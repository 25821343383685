import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { useForm, ValidationError } from "@formspree/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Form() {
  const [state, handleSubmit] = useForm("xpzbkdjv");
  // const [state, handleSubmit] = useForm("myyooknb");

  if (state.succeeded) {
    toast.success("Message Sent!", {
      toastId: "success1",

      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  return (
    <>
      <div className=" z0 md:mt-[-120px] relative lg:mt-[-180px] max-w-[1500px] text-[#222222] px-[28px] md:px-[40px] lg:px-[85px] mx-auto ">
        <div className="lg:py-[30px] bg-white md:shadow-lg md:px-[25px] lg:px-[50px]">
          <div className="grid  lg:pt-[0px] grid-cols-1 lg:grid-cols-3 lg:gap-[80px]"></div>
          <form onSubmit={handleSubmit} enctype="multipart/form-data">
            {/* <p className="text-[24px] py-[10px] ">Personal Information</p>
            <hr /> */}
            <div className=" flex-wrap -mx-3 mb-6 grid grid-cols-1 lg:grid-cols-2 pt-[30px]">
              <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                <label
                  className="block text-[18px] tracking-wide text-gray-700  font-regular mb-2"
                  for="grid-first-name"
                >
                  Full Name*
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="full-name"
                  type="text"
                  name="name"
                  required="true"
                />
                <ValidationError
                  prefix="Name"
                  field="name"
                  errors={state.errors}
                />
              </div>
              <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                <label
                  className="block text-[18px] tracking-wide text-gray-700  font-regular mb-2"
                  for="company"
                >
                  Company*
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="company"
                  type="text"
                  name="company"
                  required="true"
                />
                <ValidationError
                  prefix="Company"
                  field="company"
                  errors={state.errors}
                />
              </div>
              <div className="w-full lg:pt-[20px]  md:w-full px-3 mb-6 md:mb-0">
                <label
                  className="block text-[18px] tracking-wide text-gray-700  font-regular mb-2"
                  for="email"
                >
                  Email*
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="email"
                  type="email"
                  name="email"
                  required="true"
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </div>
              <div className="w-full  lg:pt-[20px] md:w-full px-3">
                <label
                  className="block text-[18px] tracking-wide text-gray-700  font-regular mb-2"
                  for="phone"
                >
                  Phone Number
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="phone"
                  type="number"
                  name="phone"
                />
                <ValidationError
                  prefix="Phone"
                  field="phone"
                  errors={state.errors}
                />
              </div>

              {/* <p className="text-[24px] pt-[20px] pb-[10px]">Profile</p>
            <hr /> */}
              <div className="w-full pt-[20px]  px-3 mb-6 md:mb-0">
                <label
                  className="block text-[18px] tracking-wide text-gray-700  font-regular mb-2"
                  for="grid-state"
                >
                  Region
                </label>
                <div className="relative">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    name="Region"
                    required=""
                  >
                    <option value="North America">North America</option>
                    <option value="South America">South America</option>
                    <option value="Europe">Europe</option>
                    <option value="Middel East">Middle East</option>
                    <option value="Asia Pacific">Asia Pacific</option>
                    <option value="Africa">Africa</option>
                    <option value="India">India</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="w-full lg:pt-[20px] px-3 mb-6 md:mb-0">
                <label
                  className="block text-[18px] tracking-wide text-gray-700  font-regular mb-2"
                  for="grid-state"
                >
                  Inquiry Type
                </label>
                <div className="relative">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    name="state"
                    required=""
                  >
                    <option value="Request for services">
                      Request for Services
                    </option>
                    <option value="Hire Developers">Hire Developers</option>
                    <option value="Alumni">Alumni</option>
                    <option value="Career Seekers">Career Seekers</option>
                    <option value="Investor Relations">
                      Investor Relations
                    </option>
                    <option value="Media">Media</option>
                    <option value="Request for demo">Request for demo</option>
                    <option value="Partners">Partners</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex-wrap -mx-3 mb-6 ">
              <div className="w-full md:w-full px-3 mb-6 md:mb-0">
                <label
                  className="block text-[18px] tracking-wide text-gray-700  font-regular mb-2"
                  for="message"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="6"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="How can we help you?"
                ></textarea>
                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />
              </div>
            </div>

            <div className=" pt-[15px] ">
              <button
                type="submit"
                disabled={state.submitting}
                className="text-[16px] lg:text-[18px] py-[8px] px-[12px] lg:py-[10px] md:py-[10px] lg:px-[30px] md:px-[15px] flex text-[#222222] border border-black"
              >
                Submit
                <div className="lg:pl-[20px] md:pl-[10px] pl-[5px] m-auto">
                  {state.submitting ? (
                    <svg
                      role="status"
                      className="inline w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-gray-300 fill-green-500"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  ) : (
                    <IoIosArrowForward />
                  )}
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default Form;
